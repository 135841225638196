
import React, { useState }  from 'react';
import TotalCompanyView from './TotalCompanyView';
import CashLedger from './CashLedger';
import TypeCurrencyView from './TypeCurrencyView';
import loaderIcon from '../../../assets/loader.svg';

function MonthlyView({selectedDate}) {
  const [loader, setLoader] = useState(false);
  const [totalPaidMoney, setTotalPaidMoney] = useState(0);

  const loaderStatus = (status) => {
    setLoader(status);
  }

  const getTotalMoney = (value) => {
    setTotalPaidMoney(value);
  }

  return (
    <>
      { loader && (<img src={loaderIcon} alt="Carregando..." width={100} height={100} />) }
      <div style={{  width: '1280px', margin: '20px auto 0', display: loader ? 'none' : 'block' }}>
        <TypeCurrencyView selectedDate={selectedDate} loaded={loaderStatus} totalMoney={getTotalMoney} />
        <div className='split-report'>
          <TotalCompanyView selectedDate={selectedDate} loaded={loaderStatus} />
        </div>
        <div className='split-report'>
          <CashLedger selectedDate={selectedDate} loaded={loaderStatus} totalPaidMoney={totalPaidMoney} />
        </div>
      </div>
    </>
  );
};

export default MonthlyView;
