import React, { useState, useEffect } from 'react';

const DatePagination = ({ onDateChange }) => {
  const [selectedMonth, setSelectedMonth] = useState(getDefaultMonth());

  const handleMonthChange = (event) => {
    const newSelectedMonth = event.target.value;
    setSelectedMonth(newSelectedMonth);
  };

  const increaseMonth = () => {
    const currentMonth = new Date(`${selectedMonth}-01T12:00:00`);
    currentMonth.setMonth(currentMonth.getMonth() + 1);
    if (currentMonth.getMonth() === 12) {
      currentMonth.setMonth(0); // Set it to January
      currentMonth.setFullYear(currentMonth.getFullYear() + 1); // Increase the year
    }
    const formattedDate = formatMonth(currentMonth);
    setSelectedMonth(formattedDate);
  };

  const decreaseMonth = () => {
    const currentMonth = new Date(`${selectedMonth}-01T12:00:00`);
    currentMonth.setMonth(currentMonth.getMonth() - 1);
    if (currentMonth.getMonth() === -1) {
      currentMonth.setMonth(11); // Set it to December
      currentMonth.setFullYear(currentMonth.getFullYear() - 1); // Decrease the year
    }
    const formattedDate = formatMonth(currentMonth);
    setSelectedMonth(formattedDate);
  };

  function getDefaultMonth() {
    const now = new Date();
    now.setDate(1); // Set the day to 1st of the month
    now.setHours(12, 0, 0, 0); // Set the time to 12:00:00
    const yearMonth = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
    return yearMonth;
  }

  const formatMonth = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits
    const formattedDate = `${year}-${month}`;
    return formattedDate;
  };

  useEffect(() => {
    onDateChange(selectedMonth);
  }, [selectedMonth, onDateChange]);

  return (
    <div className='pagination' style={{ marginTop: '20px' }}>
      <button onClick={decreaseMonth}>&lt;</button>
      <input
        type="month"
        value={selectedMonth}
        lang="pt"
        className='inputDate'
        style={{ width: '130px' }}
        onChange={handleMonthChange}
      />
      <button onClick={increaseMonth}>&gt;</button>
    </div>
  );
};

export default DatePagination;
