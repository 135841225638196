import React, { useState, useEffect } from 'react';

import suppliersService from '../../../services/suppliers';
import companiesService from '../../../services/companies';
import constructionsService from '../../../services/constructions';

import FilterBar from '../../../components/FilterBar';
import ScrollableTable from '../../../components/DataGrid/DataGrid';
import DatePagination from '../../../components/DatePagination';

import Utils from '../../../components/Utils/Utils';
import loaderIcon from '../../../assets/loader.svg';

function SupplierConstructionView() {
  const [expenses, setExpenses] = useState([]);

  const [suppliers, setSuppliers] = useState([]);
  const [constructions, setConstructions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [getRequestComp, setGetRequestComp] = useState(0);
  const [loader, setLoader] = useState(false);
  const [typeSearchString, setTypeSearchString] = useState('');

  useEffect(() => {
    const getSuppliers = async () => {
      try {
        const supplier = await suppliersService.getAll();
        setSuppliers(supplier.data);
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    const getCompanies = async () => {
      try {
        const company = await companiesService.getAll();
        setCompanies(company.data);
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    const getConstructions = async () => {
      try {
        const construction = await constructionsService.getAll();
        setConstructions(construction.data);
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    getSuppliers();
    getCompanies();
    getConstructions();
    setSelectedDate(getMonthYear());
  }, []);

  const getMonthYear = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so we add 1
    const formattedDate = `${year}-${month}`;

    return formattedDate;
  }

  const handleFilterExpenses = (filteredExpenses) => {
    setExpenses([]);
    setTimeout(() => {
      setExpenses(filteredExpenses);
      setLoader(false);
    }, 0)
  };

  const getCompanyName = (id) => {
      const companyID = id;
      const company = companies.find((c) => c.companyID === companyID);
      return company ? company.name : 'Unknown company';
  };

  const getConstructionName = (id) => {
    const constructionID = id;
    const construction = constructions.find((c) => c.constructionID === constructionID);
    return construction ? construction.name : 'Unknown construction';
  };

  const getSupplierName = (id) => {
    const supplierID = id;
    const supplier = suppliers.find((c) => c.supplierID === supplierID);
    return supplier ? supplier.name : 'Unknown supplier';
  };

  const handleDateChange = (date) => {
    if(typeof date !== 'object' && date !== null) {
      setSelectedDate(date);
      setTypeSearchString(date);
    }
  };

  const dynamicColumns = [
    {
      Header: 'Empresa',
      accessor: 'companyName',
      width: 200,
      textAlign: 'left',
    },
    {
      Header: 'Obra',
      accessor: 'constructionName',
      width: 140,
      textAlign: 'left',
    },
    {
      Header: 'Fornecedor',
      accessor: 'supplierName',
      width: 140,
      textAlign: 'left',
    },
    {
      Header: 'Valor',
      accessor: 'amount',
      width: 140,
      textAlign: 'left',
    },
    {
      Header: 'Data Pagamento',
      accessor: 'paymentDate',
      width: 140,
      textAlign: 'left',
    },
  ]

  let totalExpenses = 0;
  const rows = expenses.map((expense, index) => {
    totalExpenses = totalExpenses + expense.amount;
    const paymentDate = expense.paymentDate === 'null' || !expense.paymentDate ? '0000-00-00' : expense.paymentDate.split('-');

    console.log('paymentDate: ', paymentDate);

    return {
      companyName: getCompanyName(expense.companyID),
      constructionName: getConstructionName(expense.constructionID),
      supplierName: getSupplierName(expense.supplierID),
      amount: Utils.currencyFormat(expense.amount),
      paymentDate: paymentDate[2] === '0' ? '-' : `${paymentDate[2]}/${paymentDate[1]}/${paymentDate[0]}`
    };
  });

  const tableFooter = (
    <>
      <tr className='tfoot'>
        <td colSpan={3}><strong>Total:</strong></td>
        <td colSpan={2}><strong>{ Utils.currencyFormat(totalExpenses) }</strong></td>
      </tr>
    </>
  );

  // Render the data
  return (
    <>
      <div className='conciliation-screen supplier-construction-screen'>
        <FilterBar companies={companies} constructions={constructions} selectedDate={selectedDate} suppliers={suppliers} onFilterExpenses={handleFilterExpenses} requestComp={getRequestComp} typeSearchP={typeSearchString} />
        <div className='wrapper-date-filter'>
          <DatePagination onDateChange={handleDateChange} />
        </div>
      </div>
      <div className='wrapper-report'>
        {
          loader ? (
            <img src={loaderIcon} alt="Carregando..." width={100} height={100} />
          ) : (
            <ScrollableTable headers={dynamicColumns} height={'100%'} dataRows={rows} footer={tableFooter} />
          )
        }
      </div>
    </>
  );
}


export default SupplierConstructionView;
