import React from 'react'

import './PrimaryButton.scss'

const PrimaryButton = ({ children, fullWidth, onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={'btn--primary' + (fullWidth ? ' btn--primary--full' : '')}
      type="button"
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default PrimaryButton
