import React, { useState, useCallback, useMemo } from 'react';
import { PatternFormat } from 'react-number-format';

const MaskInput = ({ type, onValueChange, onBlur, placeholder, tabIndex, className, validation, defaultValue, alignment }) => {
  const [value, setValue] = useState('');

  const handleInputChange = useCallback((values) => {
    setValue(values.formattedValue);
    onValueChange && onValueChange({ formattedValue: values.formattedValue, type, validation });
  }, [onValueChange, type, validation]);
  
  const handleDefaultInputChange = useCallback((e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, '');
    const formattedValue = formatCurrency(inputValue);
    setValue(formattedValue);
    onValueChange && onValueChange({ formattedValue: formattedValue, type, validation });
  }, [onValueChange, type, validation]);
  
  const handleBlur = useCallback((e) => {
    onBlur && onBlur(e.target.value);
  }, [onBlur]);

  const formatCurrency = (q) => {
    if (!Number(q)) return "";

    const amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(q / 100);
  
    return `${amount}`;
  };

  const getFormatOptions = useMemo(() => {
    switch (type) {
      case 'phone':
        return { format: '(##) ####-#####', mask: '_', allowEmptyFormatting: true };
      case 'cnpj':
        return { format: '##.###.###/####-##', mask: '_', allowEmptyFormatting: true };
      default:
        console.warn(`Unsupported MaskInput type: ${type}`);
        return {};
    }
  }, [type]);  

  return (
    <div>
      {type === 'amount' ? (
        <input
          type="text"
          value={defaultValue !== '0' ? defaultValue : value}
          placeholder={placeholder}
          onChange={handleDefaultInputChange}
          onBlur={handleBlur}
          tabIndex={tabIndex}
          className={className}
          style={{textAlign: alignment ? alignment : 'right'}}
        />
      ) : (
        <PatternFormat
          {...getFormatOptions}
          value={defaultValue.length ? defaultValue : value}
          placeholder={placeholder}
          onValueChange={handleInputChange}
          onBlur={handleBlur}
          tabIndex={tabIndex}
          className={className}
        />
      )}
    </div>
  );
};

export default MaskInput;
