import React, { useState, useEffect } from "react"
import suppliersService from '../../services/suppliers';
import ScrollableTable from '../../components/DataGrid/DataGrid';

import './ModalSuppliers.scss';

import editIcon from '../../assets/edit-icon.png';
// import deleteIcon from '../../assets/delete-icon.png';

function ModalSuppliers({onCloseModal, supplierData}) {
  const [suppliers, setSuppliers] = useState([]);
  const [deleteSup, setDelete] = useState(0);

  const TableHeaders = [{
    Header: '-',
    columns: [
      {
          Header: 'Fornecedor',
          accessor: 'supplier',
          textAlign: 'left',
          width: 220,
      },
      {
        Header: 'Ações',
        accessor: 'action',
        textAlign: 'left',
        width: 120,
      }
    ]
  }];

  const rows = suppliers.map((value, index) => {
    return {
      supplier: value.name,
      action: (<>
        {/* <button style={{ float: 'right' }} onClick={() => setDelete(1)}><img src={deleteIcon} style={{ width: '18px', height: '18px' }} /></button> */}
        <button style={{ float: 'right' }} onClick={() => supplierData(suppliers[index])}><img src={editIcon} alt="Editar Fornecedor" style={{ width: '18px', height: '18px' }} /></button>
      </>),
      key: index,
    }
  });

  useEffect(() => {
    const getSuppliers = async () => {
      try {
        const supplier = await suppliersService.getAll();
        setSuppliers(supplier.data);
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    getSuppliers();
  }, []);

  return (
    <>
      <div className='modal-suppliers'>
        <div className="inner-content">
          {!deleteSup ? (
            <>
              <button className="btn-close" onClick={() => onCloseModal(false)}>X</button>
              <h3>Fornecedores Cadastrados</h3>
              {rows.length > 0 ? (
                <ScrollableTable headers={TableHeaders} height={'60vh'} dataRows={rows} />
              ) : (
                <p>Carregando...</p>
              )}
            </>
          ) : (
            <>
              <div className="wrapper-delete">
                <h3>Excluir Fornecedor?</h3>
                <button className={'btn--primary'}>Sim</button>
                <button className={'btn--primary'} onClick={() => setDelete(0)}>Não</button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ModalSuppliers;
