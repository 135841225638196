import React, { useState } from 'react';
import './Reports.scss';
import printIcon from '../../assets/print-icon.svg';

import MonthlyView from './views/MonthlyView';
import AnnualView from './views/AnnualView';
// import AppropriationView from './views/Appropriation';
import SupplierConstructionView from './views/SupplierConstructionView';

import RetentionView from './views/RetentionView';

import DatePagination from '../../components/DatePagination';

const Menu = () => {
  const [activeButton, setActiveButton] = useState(1);
  const [selectedDate, setSelectedDate] = useState({});

  const menuButtons = [
    { id: 1, label: 'Mensal', view: <MonthlyView selectedDate={selectedDate} /> },
    { id: 2, label: 'Anual', view: <AnnualView /> },
    { id: 3, label: 'Fornecedor/Obras', view: <SupplierConstructionView /> },
    { id: 4, label: 'Retenções', view: <RetentionView selectedDate={selectedDate} /> }
  ];

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const handleDateChange = (date) => {
    if(typeof date !== 'object' && date !== null) {
      setSelectedDate(date);
    }
  };

  return (
    <>
      <div className='reports'>
        <h2>Relatório { menuButtons.find(item => item.id === activeButton)?.label } - Valores Pagos</h2>
        <ul>
          {menuButtons.map((button) => (
            <li key={button.id}>
              <button
                onClick={() => handleButtonClick(button.id)}
                className={activeButton === button.id ? 'active' : ''}
              >
                {button.label}
              </button>
            </li>
          ))}
        </ul>

        {/* Print button */}
        { 
          activeButton === 1 && 
            <button
              className='print-button'
              onClick={() => window.print()}
            >
              <img src={printIcon} width={24} height={24} alt="Imprimir" />
            </button>
        }
        <div>
          {/* Render the selected view */}
          {(activeButton === 1 || activeButton === 4) && <DatePagination onDateChange={handleDateChange} />}
          {menuButtons.find((button) => button.id === activeButton)?.view}
        </div>
      </div>
    </>
  );
};

export default Menu;
