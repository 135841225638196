import React, { useState, useMemo, useCallback } from 'react';
import { useTable } from 'react-table';
import './DataGrid.scss';

const FixedHeaderTable = ({ headers, height, dataRows, footer, onHeaderClick, onDeleteClick }) => {
  const data = useMemo(() => dataRows, [dataRows]);
  const columns = useMemo(() => headers, [headers]);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
  );

  const handleHeaderClick = useCallback((columnId) => {
    setSortColumn((prevColumn) => {
      const isSameColumn = prevColumn === columnId;
      setSortOrder((prevOrder) => (isSameColumn && prevOrder === 'asc' ? 'desc' : 'asc'));
      return columnId;
    });
    if (onHeaderClick) {
      onHeaderClick(columnId, sortOrder === 'asc' ? 'desc' : 'asc');
    }
  }, [sortOrder, onHeaderClick]);

  const memoizedHeaders = useMemo(() => (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th
            {...column.getHeaderProps([
              {
                style: {
                  width: column.width,
                  textAlign: column.textAlign,
                  cursor: column.id === 'dueDate' || column.id === 'amount' ? 'pointer' : 'default',
                },
                'data-show': column.printMode
              },
            ])}
              onClick={() => {
                if (column.id === 'dueDate' || column.id === 'amount') {
                  handleHeaderClick(column.id);
                }
              }}
            >
              {column.render('Header')}
              <span>
                {(column.id === 'dueDate' || column.id === 'amount') && 
                  (sortColumn === column.id ? (sortOrder === 'asc' ? ' ▲' : ' ▼') : ' ▲')}
              </span>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  ), [headerGroups, sortColumn, sortOrder, handleHeaderClick]);

  return (
    <div
      className="table-container"
      style={{
        overflowX: 'auto',
        overflowY: 'auto',
        height: 'auto',
        maxHeight: `calc(${height} - 120px)`,
      }}
    >
      <table {...getTableProps()} style={{ minWidth: '100%', borderCollapse: 'collapse' }}>
        {memoizedHeaders}
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={
                  row.original?.paid && row.original?.paid?.props?.checked
                    ? row.original?.conciliation && row.original?.conciliation?.props?.checked
                      ? onDeleteClick ? onDeleteClick === row.original?.expenseID ? 'hasConciliation paid isDeleting' : 'hasConciliation paid' : 'hasConciliation paid' 
                      : onDeleteClick ? onDeleteClick === row.original?.expenseID ? 'paid isDeleting' : 'paid' : 'paid'
                    : onDeleteClick ? onDeleteClick === row.original?.expenseID ? 'isDeleting' : '' : ''
                }
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps([
                      {
                        className:
                          row.original?.retention?.props?.checked && cell.column.className,
                          ['data-show']: cell.column.printMode
                      },
                    ])}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
          {footer}
        </tbody>
        {footer && <tfoot className='no-print'>{footer}</tfoot>}
      </table>
    </div>
  );
};

export default FixedHeaderTable;
