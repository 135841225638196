import React, { Component } from "react";
import { NavLink, Redirect } from 'react-router-dom';
import logo from '../../assets/logo-white.jpg';

import "./Header.scss";

class Header extends Component {
  constructor() {
    super();

    this.state = {
      loggedOut: false,
    };

    this.logout = this.logout.bind(this);
  }

  logout(e) {
    // e.preventDefault()
    return new Promise((resolve, reject) => {
      sessionStorage.clear();
      sessionStorage.removeItem('hasReloaded');
      sessionStorage.removeItem('DonFelipePermission');
      this.setState({ loggedOut: true });
      resolve();
    });
  }

  isLoggedIn = () => {
    const token = sessionStorage.getItem('DonFelipeToken');
    return (token !== null && token !== '');
  }

  render() {
    if (!this.isLoggedIn()) {
      return <Redirect to="/login" />;
    } else {
      const permission = parseInt(sessionStorage.getItem('DonFelipePermission'), 10);

      return (
        <div className="header">
          <div className="wrapper-content">
            <img className="header__logo" src={logo} alt="Logo Don Felipe" />
            <div className="header__tabs-container">
              <ul>
                {(permission === 1 || permission === 3) && (
                  <>
                  <li>
                      <div className="dropdown">
                        <div className="dropbtn">Cadastros</div>
                        <div className="dropdown-content">
                          <NavLink className='tab' to='/cadastro-fornecedor' activeClassName="selected">Cadastro de Fornecedores</NavLink>
                          {
                            permission === 1 && 
                            (
                            <>
                              <NavLink className='tab' to='/cadastro-empresas' activeClassName="selected">Cadastro de Empresas</NavLink>
                              <NavLink className='tab' to='/cadastro-apropriacao' activeClassName="selected">Cadastro de Apropriação</NavLink>
                              <NavLink className='tab' to='/cadastro-obracc' activeClassName="selected">Cadastro de Obra/CC</NavLink>
                            </>
                            )
                          }
                        </div>
                      </div>
                    </li>
                    <li>
                      <NavLink className='tab' to='/cadastro-despesas' activeClassName="selected">Cadastro de Despesas</NavLink>
                    </li>
                  </>
                )}
                <>
                  <li>
                    <NavLink className='tab' to='/pagamento-conciliacao' activeClassName="selected">Pagamento e Conciliação</NavLink>
                  </li>
                </>
                {permission < 3 && (
                  <>
                    <li>
                      <NavLink className='tab' to='/relatorios' activeClassName="selected">Relatórios</NavLink>
                    </li>
                  </>
                )}
                </ul>
                </div> 
                 

                <div className="no-print">
                  <ul>
                <li>
                  <span className="exit" onClick={this.logout}>Sair</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Header;