import React, { useState, useEffect } from "react"
import constructionsService from '../../services/constructions';
import ScrollableTable from '../DataGrid/DataGrid';

import './ModalConstructions.scss';

import editIcon from '../../assets/edit-icon.png';
// import deleteIcon from '../../assets/delete-icon.png';

function ModalConstructions({onCloseModal, constructionData}) {
  const [constructions, setConstructions] = useState([]);
  const [deleteSup, setDelete] = useState(0);

  const TableHeaders = [{
    Header: '-',
    columns: [
      {
          Header: 'Construção',
          accessor: 'construction',
          textAlign: 'left',
          width: 220,
      },
      {
        Header: 'Ações',
        accessor: 'action',
        textAlign: 'left',
        width: 120,
      }
    ]
  }];

  const rows = constructions.map((value, index) => {
    return {
      construction: value.name,
      action: (<>
        {/* <button style={{ float: 'right' }} onClick={() => setDelete(1)}><img src={deleteIcon} style={{ width: '18px', height: '18px' }} /></button> */}
        <button style={{ float: 'right' }} onClick={() => constructionData(constructions[index])}><img src={editIcon} alt="Editar Fornecedor" style={{ width: '18px', height: '18px' }} /></button>
      </>),
      key: index,
    }
  });

  useEffect(() => {
    const getConstructions = async () => {
      try {
        const construction = await constructionsService.getAll();
        setConstructions(construction.data);
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    getConstructions();
  }, []);

  return (
    <>
      <div className='modal-constructions'>
        <div className="inner-content">
          {!deleteSup ? (
            <>
              <button className="btn-close" onClick={() => onCloseModal(false)}>X</button>
              <h3>Obras/CC Cadastradas</h3>
              {rows.length > 0 ? (
                <ScrollableTable headers={TableHeaders} height={'60vh'} dataRows={rows} />
              ) : (
                <p>Carregando...</p>
              )}
            </>
          ) : (
            <>
              <div className="wrapper-delete">
                <h3>Excluir Obra/CC?</h3>
                <button className={'btn--primary'}>Sim</button>
                <button className={'btn--primary'} onClick={() => setDelete(0)}>Não</button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ModalConstructions;
