import { useEffect, useRef } from "react";

const usePrevious = (value) => {
    const ref = useRef();
    
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    
    return ref.current; //in the end, return the current ref value.
}

const currencyFormat = (value) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}


const BLACKLIST = ['00000000000000','11111111111111','22222222222222','33333333333333','44444444444444','55555555555555','66666666666666','77777777777777','88888888888888','99999999999999','12345678909876'];

const isValidCNPJ = (cnpj) => {
    // Remove non-digits
    const cleanCNPJ = cnpj.replace(/[^\d]/g, '');

    // Check against the blacklist
    if (BLACKLIST.includes(cleanCNPJ)) {
        return false; // CNPJ is in the blacklist
    }

    // Your other CNPJ validation logic here
    // ...

    return /^\d{14}$/.test(cleanCNPJ); // Basic length check
};

export default { usePrevious, currencyFormat, isValidCNPJ };